import React, { useEffect, useState } from "react";
import "./Maindashboard.css";
import {
  generateOtpForZepxWithdrawService,
  getUserDetailService,
  manualTopupService,
  purchaseCouponCodeService,
  withdrawZepxService,
  getOtherUserDetail,
  p2ptransferservice,
  p2puseractivateservice,
  generateOtpForp2ptransferService,
  generateOtpForp2pactiveService,
} from "../services/userServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  generateOtpForWithdrawService,
  updateAutoTopEnabledService,
  withdrawService,
} from "../services/userServices";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Modal,
  Switch,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ToastError, ToastSuccess } from "../components/common/Toast";
import { plans } from "../Constants";
import { ZEPX_IN_ONE_DOLLAR } from "../Constants";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ProgressBar from "../components/common/ProgressBar";
import { LuPanelTopClose } from "react-icons/lu";
import { FaWallet } from "react-icons/fa";
import { GiMoneyStack, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { PiUsersThreeFill } from "react-icons/pi";
import { MdAttachMoney } from "react-icons/md";
import { TbUserShare } from "react-icons/tb";

export default function Maindashboard() {
  const getTokenData = useSelector((state) => state.tokenReducer);
  const getTokenDetails = useSelector((state) => state.tokenReducer);
  const [blur, setBlur] = useState(false);
  const dispatch = useDispatch();
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  //states
  const userDetails = useSelector((state) => state.userReducer);
  const navbarData = useSelector((state) => state.statusReducer);
  const [selectedOption, setSelectedOption] = useState("transfer");
  const [OtpSent ,setOtpSent] = useState(false);

  const [userId, setUserId] = useState("");
  const [detail, setDetail] = useState(null);
  const [packageAmount, setPackageAmount] = useState(null);
  const [userDetail, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openWithdrawModal, setOpenWithdraModal] = useState(false);
  const [openp2pModal, setOpenp2pModal] = useState(false);
  const [openTransfertoReserveModal, setopenTransfertoReserveModal] =
    useState(false);
  const [openZepxWithdrawModal, setOpenZepxWithdrawModal] = useState(false);

  const closeWithdrawModal = () => {
    clearData();
    setOpenWithdraModal(false);
    setBlur(false);
  };
  const closep2pModal = () => {
    clearData();
    setOpenp2pModal(false);
    setBlur(false);
  };
  const handleCloseWelcomeModal = () => {
    setShowWelcomeModal(false); // Function to close the modal
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setAmount("");
    setWalletAddress("");
    setOtp("");
    setUserId("");
    setDetail(null);
    setPackageAmount(null);
  };

  // const fetchDetails = async () => {
  //   const detail = await getDetails(userId);
  //   setDetail(detail);
  //   setPackageAmount(detail.packageAmount); // Assuming the detail object has a packageAmount field
  // };

  const closeZepxWithdrawModal = () => {
    clearData();
    setOpenZepxWithdrawModal(false);
    setBlur(false);
  };
  const [upgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const closeDepositModal = () => {
    setOpenDepositModal(false);
    setBlur(false);
  };
  const [selectedPlan, setSelectedPlan] = useState("");
  const closeUpgradePlanModal = () => {
    setOpenUpgradePlanModal(false);
    setBlur(false);
    setSelectedPlan("");
  };
  const [amount, setAmount] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [depositAmount, setDepositamount] = useState("");
  const [isWithdrawBackdropLoading, setIsWithdrawBackdropLoading] =
    useState(false);
  const [isUpgradePlanBackdropLoading, setIsUpgradePlanBackdropLoading] =
    useState(false);
  const [openBuyCoupneCode, setOpenBuyCoupneModal] = useState(false);
  const [confirmForBuyCoupneCode, SetConfirmForBuyCoupneCode] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);
  const [buyPlanPayload, setBuyPlanPayload] = useState({});
  const [OtherUser, setOtherUser] = useState({});
  const handleOpenBuyModal = () => setOpenBuyCoupneModal(true);
  const handleCloseBuyCoupneModal = () => setOpenBuyCoupneModal(false);
  const handleCloseForBuyConfirmCoupneCode = () =>
    SetConfirmForBuyCoupneCode(false);
  const handleOpenForBuyConfirmCoupneCode = () =>
    SetConfirmForBuyCoupneCode(true);

  const [coupneValue, setCoupnePlanValue] = React.useState(25);

  const handleChange = (event) => {
    setCoupnePlanValue(event.target.value);
  };

  const clearData = () => {
    setAmount("");
    setWalletAddress("");
    setOtp("");
    setIsOtpSend(false);
  };

  const navigate = useNavigate();

  const calculateJoiningAmount = (plan) => {
    try {
      let i = parseInt(plan);
      let res = 0;
      while (i >= 25) {
        res = res + i;
        i = i / 2;
      }
      return res;
    } catch (error) {}
  };

  const findRankOfUser = (plan) => {
    let rank = 1;
    let index = plans.findIndex((e) => Number(e) === Number(plan));
    if (index !== -1) {
      rank = index + 1;
    }
    return rank;
  };

  const buyPlanButtonHandler = () => {
    try {
      navigate("/payment", buyPlanPayload);
    } catch (error) {}
  };

  //function to get user details
  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      let res = await getUserDetailService(getTokenData.token);
      localStorage.setItem("userDetails", JSON.stringify(res?.data?.result));
      // console.log(`this is updated console., J${JSON.stringify(res?.data?.result)}`)
      if (res?.data?.result?.status === 0) {
        setIsUserActive(false);
        // console.log('buyPlanPayload', buyPlanPayload)
        setBuyPlanPayload({
          state: {
            email: res.data.result.email,
            amount: res.data.result.payableAmount * ZEPX_IN_ONE_DOLLAR,
            amountInBUSD: res.data.result.payableAmount,
          },
        });
      } else {
        setIsUserActive(true);
      }

      dispatch({
        type: "SET_REFERRAL_ID",
        payload: res.data.result.ownReferralCode,
      });
      dispatch({
        type: "SET_WALLET_AMOUNT",
        payload: res.data.result.walletAmount,
      });
      dispatch({
        type: "SET_OLD_WALLET_AMOUNT",
        payload: res.data.result.unclearedAmount,
      });
      dispatch({
        type: "SET_AUTO_TOPUP",
        payload: res?.data?.result?.autoTopupEnabled,
      });
      dispatch({
        type: "SET_RANK",
        payload: findRankOfUser(res.data.result.joiningDetails.selectedPlan),
      });
      dispatch({
        type: "SET_PROFILE_IMAGE",
        payload: res.data.result.image,
      });
      dispatch({
        type: "SET_USER_DETAILS_DOJ",
        payload: res.data.result.joiningDetails?.dateTimestamp,
      });
      setUserDetails(res.data.result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let totalp2pWithdarwable = userDetail.walletAmount + userDetail.p2pamount;
  const generateOtpforp2p = async () => {
    try {
      if (amount === "") {
        ToastError("Enter Valid Amount");
        return;
      }
    
      if (userId === "") {
        ToastError("Enter Valid User ID to transfer");
        return;
      }

      if (amount > totalp2pWithdarwable) {
        ToastError("you have insufficient amount to transfer");
        return;
      } 

      setIsWithdrawBackdropLoading(true);
      await generateOtpForp2ptransferService(
        {
          amount: amount,
          walletAddress: userId,
        },
        getTokenDetails.token
      );
      setIsWithdrawBackdropLoading(false);
      ToastSuccess("An OTP has been sent to your email.");
      setIsOtpSend(true);
    } catch (error) {
      setIsWithdrawBackdropLoading(false);
      ToastError("Error: " + error?.response?.data?.error?.message);
      setIsOtpSend(false);
    }
  };

  
  const generateOtp = async () => {
    try {
      if (amount === "") {
        ToastError("Enter Valid Amount");
        return;
      }
      if (Number(amount) < 15) {
        ToastError("Minimum Withdraw Amount Is $15");
        return;
      }
      if (walletAddress === "") {
        ToastError("Enter Valid User ID to transfer");
        return;
      }
      console.log(walletAddress)
      setIsWithdrawBackdropLoading(true);
      await generateOtpForWithdrawService(
        {
          amount: amount,
          walletAddress: walletAddress,
        },
        getTokenDetails.token
      );
      setIsWithdrawBackdropLoading(false);
      ToastSuccess("An OTP has been sent to your email.");
      setIsOtpSend(true);
    } catch (error) {
      setIsWithdrawBackdropLoading(false);
      ToastError("Error: " + error?.response?.data?.error?.message);
      setIsOtpSend(false);
    }
  };

  const generateOtpForZepxWithdraw = async () => {
    try {
      if (amount === "") {
        ToastError("Enter Valid Amount");
        return;
      }
      if (Number(amount) < 1000) {
        ToastError("Minimum Withdraw Amount Is 1000 ZEPX");
        return;
      }

      if (Number(amount) > userDetail?.totalWithawableZepx) {
        ToastError(
          `You cannot withdraw more than ${userDetail?.totalWithawableZepx}`
        );
        return;
      }

      if (walletAddress === "") {
        ToastError("Enter Valid Wallet Address");
        return;
      }
      setIsWithdrawBackdropLoading(true);
      await generateOtpForZepxWithdrawService(
        {
          amount: amount,
          walletAddress: walletAddress,
        },
        getTokenDetails.token
      );
      setIsWithdrawBackdropLoading(false);
      ToastSuccess("An OTP has been sent to your email.");
      setIsOtpSend(true);
    } catch (error) {
      setIsWithdrawBackdropLoading(false);
      ToastError("Error: " + error?.response?.data?.error?.message);
      setIsOtpSend(false);
    }
  };

  const withdrawAmount = async () => {
    try {
      if (otp === "") {
        ToastError("Enter Valid OTP");
        return;
      }
      setIsWithdrawBackdropLoading(true);
      await withdrawService(
        {
          amount: amount,
          walletAddress: walletAddress,
          otp: otp,
        },
        getTokenDetails.token
      );
      setIsWithdrawBackdropLoading(false);
      ToastSuccess("Withdraw Success.");
      clearData();
      navigate("/login");
    } catch (error) {
      setIsWithdrawBackdropLoading(false);
      ToastError("Error: " + error?.response?.data?.error?.message);
    }
  };

  const withdrawZepxAmount = async () => {
    try {
      if (otp === "") {
        ToastError("Enter Valid OTP");
        return;
      }
      setIsWithdrawBackdropLoading(true);
      await withdrawZepxService(
        {
          amount: amount,
          walletAddress: walletAddress,
          otp: otp,
        },
        getTokenDetails.token
      );
      setIsWithdrawBackdropLoading(false);
      ToastSuccess("Withdraw Success.");
      clearData();
      navigate("/login");
    } catch (error) {
      setIsWithdrawBackdropLoading(false);
      ToastError("Error: " + error?.response?.data?.error?.message);
    }
  };

  const handleGetDetails = async () => {
    try {
      const response = await getOtherUserDetail(
        {
          referralid: userId,
        },
        getTokenDetails.token
      );

      if ((response.status = 200)) {
        const otUser = response?.data?.result[0];
        setOtherUser(otUser);
      } else {
        console.log(response.status, response);
        ToastError(`Something went wrong`);
      }
    } catch (error) {
      ToastError("Error: " + error);
    }
  };
  const p2pTransfer = async (sender, org_receiver, transfer_amount, otp) => {
    try {
      const data = {
        ownReferralCode: sender,
        referralid: org_receiver,
        amount: transfer_amount,
        otp: otp,
      };
     
      setIsWithdrawBackdropLoading(true);
      const response = await p2ptransferservice(data, getTokenDetails.token, otp);
      setIsWithdrawBackdropLoading(false);
      if (response.status === 200) {
        alert(
          `${response.data.msg} to ${org_receiver} amount ${transfer_amount}`
        );
        setOpenp2pModal(false);
        window.location.reload();
      } else {
        ToastError(`Something went wrong`);
      }
    } catch (error) {
      ToastError("Error: " + error);
    }
  };
  const p2pactivate = async (sender, org_receiver, otp) => {
    try {
      const data = {
        ownReferralCode: sender,
        referralid: org_receiver,
        otp : otp
      };
      const response = await p2puseractivateservice(
        data,
        getTokenDetails.token
      );
      console.log(response);
      if (response.status === 200) {
        ToastSuccess(`Id activated ${org_receiver}`)
        // alert(`${response.data.msg}`)
        setOpenp2pModal(false);
        window.location.reload();
      } else {
        alert(`Something went wrong`);
      }
    } catch (error) {
      alert("Error: " + error);
    }
  };

  const handleP2PTransferButtonClick = async () => {
    
    const sender = userDetails.referralId; // Replace with actual sender value
    const org_receiver = OtherUser.ownReferralCode; // Replace with actual receiver value
    const transfer_amount = amount; // Replace with actual amount value
    // alert (` ${transfer_amount}, ${org_receiver}, ${sender}`)
    if (otp === "") {
      ToastError("Enter Valid OTP");
      return;
    }
    if (transfer_amount > totalp2pWithdarwable) {
      ToastError("you have insufficient amount to Transfer");
      return;
    }
    p2pTransfer(sender, org_receiver, transfer_amount, otp);
  };
  
  const generateOtpforp2pActivate = async () => {
    try {
      if (userId === "") {
        ToastError("Enter Valid UserID");
        return;
      }
      if (calculateJoiningAmount(OtherUser.selectedPlan) > totalp2pWithdarwable) {
        ToastError("you have insufficient amount to update this id");
        return;
      } 
      else {
    setIsWithdrawBackdropLoading(true);
      let sender = userDetail?.ownReferralCode
      let receiver = userId
      let planeAmount = calculateJoiningAmount(OtherUser?.selectedPlan)
      let data = {
        userId: sender,
        OtherUserId: receiver,
        amount : planeAmount 
    }
    await generateOtpForp2pactiveService(
      data,
      getTokenDetails.token
    );
    
    ToastSuccess("An OTP has been sent to your email.");
    setIsWithdrawBackdropLoading(false);
    setIsOtpSend(true);
  }
  } catch (error) {
    alert(error)
    setIsWithdrawBackdropLoading(false);
    ToastError("Error: " +error);
    setIsOtpSend(false);
  }
     
  };

  const activateId = async() => {
    const sender = userDetails.referralId;
    const org_receiver = OtherUser.ownReferralCode;
    if (calculateJoiningAmount(OtherUser.selectedPlan) > totalp2pWithdarwable) {
      ToastError("you have insufficient amount to update this id");
    } else {
      await p2pactivate(sender, org_receiver, otp);
      ToastSuccess(`Id Activated : ${org_receiver}`);
    }
  };

  const depositButtonHanlder = () => {
    const amount = depositAmount;
    if (amount !== "") {
      if (!isNaN(parseInt(amount))) {
        if (amount < 10) {
          ToastError("Minimum Deposit Amount Should be 10.");
          return;
        }
        navigate("/deposit", {
          state: {
            amount: parseInt(amount),
          },
        });
      } else {
        ToastError("Enter Valid Amount");
      }
    } else {
      ToastError("Enter Valid Amount");
    }
  };

  const withdrawButtonHandler = async () => {
    try {
      if (userDetails.walletAmount < 15) {
        ToastError("Minimum Wallet Balance should be 15.");
        return;
      }
      setOpenWithdraModal(true);
      setBlur(true);
    } catch (error) {
      ToastError("Error: " + error?.response?.data?.error?.message);
    }
  };


  const transferp2pButtonHandler = async () => {
    try {
  
      setOpenp2pModal(true);
      setBlur(true);
    } catch (error) {
      ToastError("Error: " + error?.response?.data?.error?.message);
    }
  };

  const upgradePlanButtonHandler = () => {
    setOpenUpgradePlanModal(true);
    setBlur(true);
  };

  const handleBuyConfirmCoupneCode = async () => {
    try {
      handleCloseForBuyConfirmCoupneCode();
      handleCloseBuyCoupneModal();
      setIsLoading(true);
      const payload = {
        plan: coupneValue,
        zepxAmount:
          (calculateJoiningAmount(coupneValue) / 2) * ZEPX_IN_ONE_DOLLAR,
      };

      await purchaseCouponCodeService(payload, getTokenDetails.token);
      setIsLoading(false);
      ToastSuccess("Coupon Code has been sent on your registered email.");
      getUserDetails();
    } catch (error) {
      setIsLoading(false);
      ToastError("Error: " + error?.response?.data?.error?.message);
    }
  };

  const style = {
    position: "Fixed",
    top: "50%",
    left: "50%",
    right: "10%",
    transform: "translate(-50%, -50%)",
    width: "50%",

    height: "300px",
    background: "#E9EDF0",
    border: "0.5px solid #FEFEFF",
    // boxShadow:
    //   "10px 10px 24px rgba(166, 180, 200, 0.7), -12px -12px 20px rgba(255, 255, 255, 0.8)",
    borderRadius: "12px",
    boxShadow: "10px 10px 24px rgba(0,0,0,0.4)",
  };

  const upgradeModalStyle = {
    position: "Fixed",
    top: "25%",
    left: "50%",
    right: "10%",
    transform: "translate(-50%, -50%)",
    width: "50%",

    height: "300px",
    background: "#E9EDF0",
    border: "0.5px solid #FEFEFF",
    boxShadow: "10px 10px 24px rgba(0,0,0,0.4)",
    borderRadius: "12px",
  };

  const updateAutoTopup = async () => {
    try {
      let data = {
        updateAutoTopupEnabled: !userDetails.autoTopupEnabled,
      };
      setIsLoading(true);
      await updateAutoTopEnabledService(data, getTokenDetails.token);
      dispatch({
        type: "TOGGLE_AUTO_TOPUP",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ToastError("Error: " + error.response.data.error.message);
    }
  };

  const upgradePlan = async () => {
    try {
      if (selectedPlan === "") {
        ToastError("Please select a plan.");
        return;
      }

      let initialAmountPaid = calculateJoiningAmount(
        userDetail?.joiningDetails?.selectedPlan
      );

      let newSelectedPlan = parseInt(selectedPlan);
      let newPlanTotalAmount = calculateJoiningAmount(newSelectedPlan);
      let netPayable = newPlanTotalAmount - initialAmountPaid;
      // console.log(userDetail.reserveAmount)
      // console.log(userDetail.walletAmount)

      let walletBalance = Number(userDetails.walletAmount);

      let reserveBalance = Number(userDetail.reserveAmount.toFixed(2));

      let P2pBalance = Number(userDetail?.p2pamount);

      // console.log(reserveBalance, typeof reserveBalance)
      let totalWalletBalance = walletBalance + reserveBalance + P2pBalance;
      // console.log(userDetails.walletAmount + userDetails.reserveAmount)

      // console.log(totalWalletBalance, typeof WalletBalance, " typeoftotal Balance")

      if (totalWalletBalance >= netPayable) {
        // console.log(userDetail.reserveAmount)
        // console.log(userDetail.walletAmount)

        setIsUpgradePlanBackdropLoading(true);
        let dataToSend = {};
        dataToSend.selectedPlan = parseInt(selectedPlan);
        await manualTopupService(dataToSend, getTokenDetails.token);
        setIsUpgradePlanBackdropLoading(false);
        closeUpgradePlanModal();
        ToastSuccess("Plan Upgraded.");
        await getUserDetails();
      } else {
        ToastError(
          "Insufficient Wallet Amount. Kindly Deposit Funds and try again."
        );
      }
    } catch (error) {
      setIsUpgradePlanBackdropLoading(false);
      ToastError("Error: " + error);
    }
  };
  const withdraw4x = () => {
    let withdrawTotal = (
      userDetail?.usdtWithdrawal +
      (userDetail?.busdWithdrawal +
        userDetail?.zepxWithdrawal / ZEPX_IN_ONE_DOLLAR)
    ).toFixed(2);
    let planPackageAmount =
      calculateJoiningAmount(userDetail?.joiningDetails?.selectedPlan) * 4;
    let withdrawPercentage = (
      (withdrawTotal / planPackageAmount) *
      100
    ).toFixed(2);

    return withdrawPercentage;
  };

  const income4x = () => {
    let incomeTotal = userDetail?.totalIncome;
    let planPackageAmount =
      calculateJoiningAmount(userDetail?.joiningDetails?.selectedPlan) * 4;
    let incomePercentage = ((incomeTotal / planPackageAmount) * 100).toFixed(2);

    return incomePercentage;
  };

  const handleCoupneBuy = () => {
    handleOpenForBuyConfirmCoupneCode();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full relative min-h-screen bg-bgColor dark:bg-darkbgColor   px-5 mb-5 flex flex-col items-center  pt-10">
        {/* <LogoutModal /> */}
        <div className="flex flex-wrap justify-between items-start  w-full">
          <div className=" w-full lg:w-[30%]">
            <div className="   w-full lg:w-[100%]  bg-white dark:bg-darktheme  rounded-md px-4 py-3 relative mt-5">
              <div className="rounded-md shadow-xl w-10 mx-auto h-10 flex justify-center items-center   bg-green-500 ">
                <AccountBalanceWalletIcon
                  className="text-[white!important]"
                  fontSize="large"
                />
              </div>
              <h2 className="text-xl text-black dark:text-white font-semibold">
                WALLET
              </h2>
              <div className="w-full flex justify-between items-center ">
                <p className="text-4xl font-bold text-black dark:text-white w-[95%]">
                  ${userDetails.walletAmount.toFixed(2)}
                </p>
              </div>

              <div className="flex items-center w-full">
                <span className="text-black dark:text-white">
                  Auto TopUp Enabled:{" "}
                </span>
                <Switch
                  checked={userDetails.autoTopupEnabled}
                  onChange={() => {
                    updateAutoTopup();
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>

              <div className="flex flex-col w-full justify-between  items-center  pt-5 border-dashed border-red-500">
                <button
                  variant="contained"
                  className="w-full bg-darkbgColor py-2 rounded-md text-white font-medium "
                  onClick={() => {
                    setOpenDepositModal(true);
                    setBlur(true);
                  }}
                >
                  Deposit
                </button>
                <button
                  variant="contained"
                  className="w-full bg-darkbgColor/40 shadow-lg mt-3 py-2 rounded-md text-white font-medium "
                  onClick={() => {
                    withdrawButtonHandler();
                  }}
                >
                  Withdraw
                </button>
                <button
                  variant="contained"
                  className="w-full text-white bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 shadow-lg mt-3 py-2 rounded-md text-white font-medium "
                  onClick={() => {
                    transferp2pButtonHandler();
                  }}
                >
                 P2P Activity 
                </button>
              </div>

              <div className="w-full mb-6 justify-between flex max-[600px]:flex-col  mt-5  border-t-2 border-theme border-dashed pt-4">
                {isUserActive === true ? (
                  <button
                    variant="contained"
                    onClick={() => {
                      upgradePlanButtonHandler();
                    }}
                    className="w-full bg-darkbgColor py-2 rounded-md text-white font-medium "
                  >
                    Upgrade Plan
                  </button>
                ) : (
                  <button
                    variant="contained"
                    onClick={() => {
                      buyPlanButtonHandler();
                    }}
                    className="w-full bg-darkbgColor mt-3 py-2 rounded-md text-theme font-medium "
                  >
                    Pay
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className=" w-full  lg:w-[68%] flex flex-wrap justify-between">
            <Card
              title={"P2P wallet"}
              value={`$
              ${userDetail?.p2pamount ? (userDetail?.p2pamount).toFixed(4) : 0}
              USDT`}
              icon={<GiReceiveMoney />}
              bgColor={"bg-green-500"}
            />
            <Card
              title={"Reserver for Topup"}
              value={` $
                  ${
                    typeof userDetail?.reserveAmount === "number"
                      ? userDetail.reserveAmount.toFixed(2)
                      : "0"
                  }
                  USDT`}
              icon={<LuPanelTopClose />}
              bgColor={"bg-theme"}
            />

            <Card
              title={"ZEPX In wallet"}
              value={` 
              ${userDetail?.zepxBalance ? userDetail?.zepxBalance : 0} zepx`}
              icon={<FaWallet />}
              bgColor={"bg-purple-500"}
            />

            <Card
              title={"Total Investment"}
              value={`$ ${
                userDetail?.joiningDetails?.selectedPlan
                  ? calculateJoiningAmount(
                      userDetail?.joiningDetails?.selectedPlan
                    )
                  : 0
              }`}
              icon={<LuPanelTopClose />}
              bgColor={"bg-red-500"}
            />

            <Card
              title={"ROI Income"}
              value={` $
              ${
                userDetail?.roiIncome ? (userDetail?.roiIncome).toFixed(4) : 0
              }`}
              icon={<GiTakeMyMoney />}
              bgColor={"bg-yellow-500"}
            />

            <Card
              title={"Direct Income"}
              value={`  $
              ${
                userDetail?.directIncome
                  ? userDetail?.directIncome.toFixed(2)
                  : 0
              }`}
              icon={<GiTakeMyMoney />}
              bgColor={"bg-blueC"}
            />

            <Card
              title={"Level Income"}
              value={` $
              ${
                userDetail?.levelIncome ? userDetail?.levelIncome.toFixed(2) : 0
              }`}
              icon={<GiMoneyStack />}
              bgColor={"bg-pink-600"}
            />

            <Card
              title={"Zepx Withdrawal"}
              value={`
              ${userDetail?.zepxWithdrawal ? userDetail?.zepxWithdrawal : 0}
              zepx`}
              icon={<PiUsersThreeFill />}
              bgColor={"bg-theme"}
            />
            <Card
              title={"USDT Withdrawal"}
              value={`$ ${
                userDetail?.busdWithdrawal + userDetail?.usdtWithdrawal
                  ? userDetail.busdWithdrawal + userDetail?.usdtWithdrawal
                  : 0
              }
              USDT`}
              icon={<PiUsersThreeFill />}
              bgColor={"bg-red-800"}
            />

            <Card
              width="lg:w-[48%]"
              title={"Total Income"}
              value={` $
              ${
                userDetail?.totalIncome ? userDetail?.totalIncome.toFixed(4) : 0
              }`}
              icon={<GiMoneyStack />}
              bgColor={"bg-blue-600"}
            />
            <Card
              width="lg:w-[48%]"
              title={"Total Withdrawl"}
              value={` $
              ${
                userDetail?.usdtWithdrawal +
                (userDetail?.busdWithdrawal +
                  userDetail?.zepxWithdrawal / ZEPX_IN_ONE_DOLLAR)
                  ? (
                      userDetail?.usdtWithdrawal +
                      (userDetail?.busdWithdrawal +
                        userDetail?.zepxWithdrawal / ZEPX_IN_ONE_DOLLAR)
                    ).toFixed(2)
                  : 0
              }
              USDT`}
              icon={<MdAttachMoney />}
              bgColor={"bg-red-500"}
            />

            <Card
              title={"Referral Members"}
              value={
                userDetail?.referralMembers ? userDetail?.referralMembers : 0
              }
              icon={<TbUserShare />}
              bgColor={"bg-orange-400"}
            />

            <Card
              title={"Downline Members"}
              value={`
              ${
                userDetail?.totalTeamMembers ? userDetail?.totalTeamMembers : 0
              }`}
              icon={<PiUsersThreeFill />}
              bgColor={"bg-pink-800"}
            />

            {/* <Card
              title={"Current Zepx value in USDT"}
              value={`${
                userDetail?.zepxBalance / ZEPX_IN_ONE_DOLLAR
                  ? (userDetail.zepxBalance / ZEPX_IN_ONE_DOLLAR).toFixed(4)
                  : 0
              }
              USDT`}
              icon={<MdAttachMoney />}
              bgColor={"bg-yellow-800"}
            /> */}
          </div>
        </div>

        {/* chart details */}

        <div className="flex w-full justify-between flex-wrap bg-bgColor dark:bg-darkbgColor items-center   py-6">
          <CircleCard lable="Weekly" rating={"10"} />
          <CircleCard lable="15 days" rating={"10"} />
          <CircleCard lable="Monthly" rating={"10"} />
          <CircleCard lable="Withdrawl Done" rating={withdraw4x()} />
          <CircleCard lable="Income recieve" rating={income4x()} />
          {/* <div className=" CircleCard">
            <div className=" rounded-md px-4 py-2 w-full h-full flex flex-col items-center justify-center   ">
              <ProgressBar rating={"10"} />
              <h3 className="text-white text-[25px]">Weekly</h3>
            </div>
            <div className=" px-4 py-2 w-full h-full flex flex-col items-center justify-center  ">
              <ProgressBar rating={"10"} />
              <h3 className="text-white text-[25px]">15 days</h3>
            </div>
            <div className=" px-4 py-2 w-full h-full flex flex-col items-center justify-center   ">
              <ProgressBar rating={"10"} />
              <h3 className="text-white text-[25px]">Monthly</h3>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-md px-4 py-2 w-full min-[1140px]:w-1/5 h-full flex flex-col items-center justify-center  GridientBg ">
            <h2 className="text-white text-[30px]">Withdrawl Done</h2>
            <ProgressBar rating={withdraw4x()} />
            <h3 className="text-white text-[25px]">4X Meter</h3>
          </div>
          <div className="bg-white shadow-md rounded-md px-4 py-2 w-full min-[1140px]:w-1/5 h-full flex flex-col items-center justify-center  GridientBg ">
            <h2 className="text-white text-[30px]"> Income recieve</h2>
            <ProgressBar rating={income4x()} />
            <h3 className="text-white text-[25px]">4X Meter</h3>
          </div> */}
        </div>
      </div>
      <Modal
        open={openWithdrawModal}
        onClose={closeWithdrawModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="dark:bg-darkbgColor bg-white translate rounded-xl p-5 overflow-y-auto shadow-lg h-[50%] w-[400px] max-sm:h-[60%] max-sm:w-[80%] absolute left-[50%] right-[50%] top-[50%] bottom-[50%]">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isWithdrawBackdropLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="flex flex-col w-full items-center justify-center h-full">
            <div className="w-full max-w-md rounded-lg  p-6">
              <h2 className="text-2xl dark:text-white font-bold text-gray-900 mb-4">
                Withdraw Yor Money
              </h2>

              <form className="flex flex-col">
                <input
                  type="number"
                  className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder="Enter Amount"
                  disabled={isOtpSend}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <input
                  type="text"
                  className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder="Enter the wallet Address"
                  disabled={isOtpSend}
                  onChange={(e) => {
                    setWalletAddress(e.target.value);
                  }}
                  value={walletAddress}
                />
                <input
                  type="text"
                  className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder="Enter the OTP"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  value={otp}
                />

                <div className="flex w-full gap-4">
                  <button
                    type="button"
                    className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                    onClick={() => {
                      generateOtp();
                    }}
                  >
                    Get OTP
                  </button>
                  <button
                    type="button"
                    className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                    onClick={() => {
                      withdrawAmount();
                    }}
                  >
                    Withdraw
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openp2pModal}
        onClose={() => setOpenp2pModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="dark:bg-darkbgColor bg-white translate rounded-xl p-5 overflow-y-auto shadow-lg h-[50%] w-[400px] max-sm:h-[60%] max-sm:w-[80%] absolute left-[50%] right-[50%] top-[50%] bottom-[50%]">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="flex flex-col w-full items-center justify-center h-full">
            <div className="w-full max-w-md rounded-lg p-6">
              <h2 className="text-2xl dark:text-white font-bold text-gray-900 mb-4">
                P2P Activity
              </h2>
              <div className="w-full flex flex-col justify-center mb-4">
                <FormControl fullWidth>
                  <InputLabel id="p2p-activity-label">
                    Select Activity
                  </InputLabel>
                  <Select
                    labelId="p2p-activity-label"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    label="Select Activity"
                  >
                    <MenuItem value="transfer">Transfer</MenuItem>
                    <MenuItem value="activate">Activate ID</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {selectedOption === "transfer" && (
                <form className="flex flex-col">
                  <p className="text-gray-900 dark:text-white mb-2">
                    Total Amount Available:{" "}
                    <strong>${totalp2pWithdarwable}</strong>
                  </p>
                  <input
                    type="number"
                    className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                    placeholder="Enter 6 Digit ID"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                  />
                  <div className="flex items-center mb-4">
                    <input
                      type="number"
                      className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150 flex-grow"
                      placeholder="Enter Amount to Transfer"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  {OtpSent && OtherUser.email && (
                    <>
                      <p className="text-gray-900 dark:text-white mb-4">
                        User Email: {OtherUser.email}
                      </p>
                      <input
                        type="text"
                        className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                        placeholder="Enter the OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                      />
                    </>
                  )}
                  <div className="flex w-full gap-4">
                    <button
                      type="button"
                      className="w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 transition ease-in-out duration-150"
                      onClick={() => {
                        generateOtpforp2p();
                        handleGetDetails();
                        setOtpSent(true); // Ensure this state variable is set when OTP is sent
                      }}
                    >
                      Get OTP
                    </button>
                    <button
                      type="button"
                      className="w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 transition ease-in-out duration-150"
                      onClick={handleP2PTransferButtonClick}
                      disabled={!OtpSent} // Disable transfer button until OTP is sent
                    >
                      Transfer
                    </button>
                  </div>
                </form>
              )}

              {selectedOption === "activate" && (
                <form className="flex flex-col">
                  <p className="text-gray-900 dark:text-white mb-2">
                    Total Amount Available:{" "}
                    <strong>${totalp2pWithdarwable}</strong>
                  </p>
                  <input
                    type="number"
                    className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                    placeholder="Enter 6 Digit ID"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                  />
                  <div className="flex w-full gap-4">
                    <button
                      type="button"
                      className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                     onClick={async () => {
                            await handleGetDetails();
                        await generateOtpforp2pActivate();
                        setOtpSent(true);
                     
                      }}
                    >
                      Get OTP
                    </button>
                  
                  </div>
                  {OtpSent &&  OtherUser.email &&
                    (OtherUser.status !== 0 ? (
                      <p className="text-red-500 dark:text-white mt-4">
                        User ID is already Activated
                      </p>
                    ) : (
                      <div className="mt-4">
                          <input
                        type="text"
                        className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                        placeholder="Enter the OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                      />
                       <button
                        type="button"
                        className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                        onClick={activateId}
                      >
                        Activate ID
                      </button>
                        <p className="text-gray-900 dark:text-white">
                          <strong> User Email:</strong> {OtherUser.email}
                        </p>
                        <p className="text-gray-900 dark:text-white">
                          <strong> Selected Package: </strong> $
                          {OtherUser.selectedPlan}
                        </p>
                        <p className="text-gray-900 dark:text-white">
                          <strong> Amount Deducted: </strong> $
                          {calculateJoiningAmount(OtherUser.selectedPlan)}
                        </p>
                      </div>
                    ))}
                </form>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {/* Zepx Withdraw Modal */}
      <Modal
        className="modal"
        open={openZepxWithdrawModal}
        onClose={closeZepxWithdrawModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isWithdrawBackdropLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="modalinside">
            <Typography
              id="modal-modal-title"
              variant="h6"
              className="modalinside_heading"
            >
              Withdrawable Zepx : {userDetail?.totalWithawableZepx}
            </Typography>{" "}
            <br></br>
            <Typography
              id="modal-modal-title"
              variant="h6"
              className="modalinside_heading"
            >
              Amount :
            </Typography>
            <input
              className="modalinside_input"
              placeholder="Enter Amount"
              disabled={isOtpSend}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              type="number"
            />
            <Typography
              id="modal-modal-title"
              variant="h6"
              className="modalinside_heading"
            >
              Wallet Address:
            </Typography>
            <input
              disabled={isOtpSend}
              value={walletAddress}
              className="modalinside_input"
              placeholder="Enter your Wallet Address"
              onChange={(e) => {
                setWalletAddress(e.target.value);
              }}
              type="text"
            />
            <div className="modalrow">
              <Button
                onClick={() => {
                  generateOtpForZepxWithdraw();
                }}
                className="modalinside_button"
              >
                Get Otp
              </Button>
              <input
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
                placeholder="Enter OTP"
                type="text"
                className="modalinside_input"
              />
              <Button
                onClick={() => {
                  withdrawZepxAmount();
                }}
                className="modalinside_button1"
              >
                Withdraw
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        className="modal"
        open={openDepositModal}
        onClose={closeDepositModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="dark:bg-darkbgColor bg-white translate rounded-xl p-5 overflow-y-auto shadow-lg h-[70%] lg:h-[400px] w-[90vw] lg:w-[400px] absolute left-[50%] right-[50%] top-[50%] bottom-[50%]">
          <div className="flex flex-col w-full items-center justify-center h-full">
            <div className="w-full max-w-md rounded-lg  p-6">
              <h2 className="text-2xl font-bold dark:text-white text-gray-900 mb-4">
                Deposit
              </h2>

              <form className="flex flex-col">
                <input
                  type="number"
                  className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  placeholder="Enter Amount"
                  onChange={(e) => setDepositamount(e.target.value)}
                  value={depositAmount}
                />

                <div className="flex w-full gap-4">
                  <button
                    type="button"
                    className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                    onClick={() => {
                      setOpenDepositModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                    onClick={() => {
                      depositButtonHanlder();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        className="modal"
        open={upgradePlanModal}
        onClose={closeUpgradePlanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="dark:bg-darktheme bg-white translate rounded-xl p-5 overflow-y-auto shadow-lg h-[50%] w-[400px] max-sm:h-[80%] max-sm:w-[80%] absolute left-[50%] right-[50%] top-[50%] bottom-[50%]">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isUpgradePlanBackdropLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="modalDepositinside">
            <Typography
              id="modal-modal-title"
              variant="h6"
              className="dark:text-white text-gray-600 "
            >
              Select Upgrade Plan
            </Typography>

            <select
              style={{ fontSize: "1vw" }}
              value={selectedPlan}
              onChange={(e) => {
                setSelectedPlan(e.target.value);
              }}
              className="w-full my-5 py-2 rounded-xl px-4"
            >
              <option value="">Select Plan</option>
              {userDetail?.joiningDetails?.selectedPlan &&
                plans.map((item, i) => {
                  if (item > userDetail?.joiningDetails?.selectedPlan) {
                    return (
                      <option key={i} value={item}>
                        USDT {item}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
            </select>

            {selectedPlan !== "" && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                className="modalinside_heading"
              >
                Amount Payable:{" "}
                {calculateJoiningAmount(selectedPlan) -
                  calculateJoiningAmount(
                    userDetail?.joiningDetails?.selectedPlan
                  )}
              </Typography>
            )}

            <div className="modalrow">
              <button
                onClick={() => {
                  upgradePlan();
                }}
                className=" w-full bg-darkbgColor dark:bg-darktheme text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
              >
                Ok
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const Card = ({ title, value, icon, width, bgColor }) => {
  return (
    <div
      className={`bg-white w-full dark:bg-darktheme text-black dark:text-white ${
        width ? width : "lg:w-[30%]"
      } p-4 py-6 rounded-lg mt-5 shadow-md  flex items-center`}
    >
      <div
        className={`h-10 w-10 rounded-full ${bgColor} text-white flex items-center  justify-center text-xl`}
      >
        {icon}
      </div>
      {/* <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#0064C1" />
        <path
          d="M14 22C14 21.4477 14.4477 21 15 21H16C16.5523 21 17 21.4477 17 22C17 22.5523 16.5523 23 16 23H15C14.4477 23 14 22.5523 14 22Z"
          fill="white"
        />
        <path
          d="M24 21C23.4477 21 23 21.4477 23 22C23 22.5523 23.4477 23 24 23H25C25.5523 23 26 22.5523 26 22C26 21.4477 25.5523 21 25 21H24Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4753 17.9158L12.7586 13.638C13.1393 12.369 14.3073 11.5 15.6321 11.5H24.3679C25.6927 11.5 26.8607 12.369 27.2414 13.638L28.5248 17.9158C29.4078 18.438 30 19.3998 30 20.5V23.5C30 24.8062 29.1652 25.9175 28 26.3293V28C28 28.2761 27.7761 28.5 27.5 28.5H26.5C26.2239 28.5 26 28.2761 26 28V26.5H14V28C14 28.2761 13.7761 28.5 13.5 28.5H12.5C12.2239 28.5 12 28.2761 12 28V26.3293C10.8348 25.9175 10 24.8062 10 23.5V20.5C10 19.3998 10.5922 18.438 11.4753 17.9158ZM15.6321 13.5H24.3679C24.8095 13.5 25.1988 13.7897 25.3257 14.2127L26.3119 17.5H13.6881L14.6743 14.2127C14.8012 13.7897 15.1905 13.5 15.6321 13.5ZM13 19.5C12.4477 19.5 12 19.9477 12 20.5V23.5C12 24.0523 12.4477 24.5 13 24.5H27C27.5523 24.5 28 24.0523 28 23.5V20.5C28 19.9477 27.5523 19.5 27 19.5H13Z"
          fill="white"
        />
      </svg> */}
      <div className="ml-3">
        <p className="text-gray-600 font-medium dark:text-[#D4DFEB]">{title}</p>
        <p className="text-xl font-bold">{value}</p>
      </div>
    </div>
  );
};

const CircleCard = ({ width, lable, rating }) => {
  return (
    <div
      className={`bg-white w-full dark:bg-darktheme text-black dark:text-white ${
        width ? width : "lg:w-[19%]"
      } p-4 py-6 rounded-lg mt-5 shadow-md `}
    >
      <h3 className="dark:text-white text-2xl text-center pb-3 font-semibold text-black">
        {lable}
      </h3>
      <ProgressBar text={"ddd"} rating={rating} />
    </div>
  );
};

// const LogoutModal = () => {
//   useEffect(() => {
//     document.body.style.overflow = "hidden";
//     return () => (document.body.style.overflow = "unset");
//   }, []);

//   return (
//     <div className="h-screen flex justify-center items-center w-screen z-50 fixed  right-0 bg-black/60  top-0 ">
//       <div className="w-[350px] dark:bg-theme/90 py-20  rounded-2xl">
//         <button
//           variant="contained"
//           className="w-full bg-darkbgColor py-2 rounded-md text-white font-medium "
//         >
//           Deposit
//         </button>

//         <button>Cancel</button>
//       </div>
//     </div>
//   );
// };
